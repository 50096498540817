import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import Menu from "../components/Menu";

// eslint-disable-next-line
export const MenuPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  advanced,
  breadcrumbs,
  options,
  body,
  helmet,
}) => {
  const PageContent = HTMLContent || Content;

  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;
  if (images && images.smallImage) smallImage = images.smallImage;

  const showTitle = options.showTitle;

  return (
    <div>
      {helmet || null}
      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="">
              {smallImage ? (
                <figure className="image is-inline-block author-icon is-pulled-right pt-0 mt-0 is-hidden-mobile">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: smallImage,
                      alt: String(title + " " + subtitle).trim(),
                      className: "author-image",
                    }}
                  />
                </figure>
              ) : null}
              <DisplayContentHeader
                title={title}
                breadcrumbs={breadcrumbs}
                showColon={true}
              />
            </div>
            <PageContent className="content" content={body} />
            {advanced && advanced.menuId ? (
              <>
                <div className="content content___menu-page">
                  <Menu
                    id={advanced.menuId}
                    start={advanced.menuStart || "."}
                    depth={advanced.menuDepth || 99}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

MenuPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  advanced: PropTypes.shape({
    menuId: PropTypes.string,
    menuStart: PropTypes.string,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const MenuPage = ({ data }) => {
  const { markdownRemark: post } = data;

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  return (
    <Layout>
      <MenuPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        advanced={post.frontmatter.advanced}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
      />
    </Layout>
  );
};

MenuPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MenuPage;

export const MenuPageQuery = graphql`
  query MenuPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showTitlesSwapped
          showDisplayContentHeader
        }
        advanced {
          menuId
          menuStart
        }
        summary
      }
    }
  }
`;
